import PropTypes from 'prop-types'

import {List} from 'immutable'
import {memo} from 'react'

import CategoryBox from './CategoryBox'

import {allResponseGroupCount} from '../../../../../lib/plan_data/itemResponsesHelper'

import './categoriesGrid.scss'

const CategoriesGrid = props => {
  const responseGroupSize = category => {
    const categoryItemResponses = props.itemResponses.filter(itemResponse => itemResponse.get('category-id') === category.get('id'))
    return allResponseGroupCount(categoryItemResponses)
  }

  return (
    <div className='categories-grid'>
      <h6 className='heading'>Your Everplan</h6>
      <div className='categories-container'>
        {
          props.categories
            .sortBy(category => category.get('sort-order'))
            .map(category => (
              <CategoryBox
                key={category.get('id')}
                category={category}
                categoryResponseGroupsSize={responseGroupSize(category)}
              />
            ))
        }
      </div>
    </div>
  )
}

CategoriesGrid.propTypes = {
  categories: PropTypes.instanceOf(List),
  itemResponses: PropTypes.instanceOf(List)
}

const areEqual = (prevProps, nextProps) => nextProps.itemResponses.equals(prevProps.itemResponses)

export default memo(CategoriesGrid, areEqual)
