import {Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState} from 'react'
import PropTypes from 'prop-types'

import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import {Cancel, Remove} from '../../blocks/Buttons'
import {TextGutterLarge} from '../../blocks/Texts'

import {CONTACTS} from '../../../../../graphql/queries/userData'
import {DELETE_CONTACT} from '../../../../../graphql/mutations/userData'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import Logger from '../../../../../lib/NewLogger'


const DeleteContactConfirmation = props => {
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const [deleteContact] = useMutation(DELETE_CONTACT, {
    update: cache => {
      const everplanId = props.userConfig.get('everplan-id')
      const {contacts} = cache.readQuery({query: CONTACTS, variables: {everplanId}})

      const updatedContacts = contacts.filter(contactData => contactData.id !== props.contactId)

      cache.writeQuery({
        query: CONTACTS,
        data: {contacts: updatedContacts},
        variables: {everplanId}
      })
    }
  })

  const removeContact = () => {
    setProcessing(true)

    Logger.log({
      name: 'user_deleted_contact',
      payload: {
        context: 'address_book'
      }
    })

    deleteContact({
      variables: {id: props.contactId},
      onCompleted: () => {
        alwaysNotify.shortSuccess(`${props.contactLabel} has been deleted.`)
      }
    })
  }

  return (
    <div className='delete-contact-confirmation'>
      <TextGutterLarge>
          Are you sure you want to delete {props.contactLabel} from your contacts?
      </TextGutterLarge>
      <ButtonGroup>
        <Cancel disabled={processing} onClick={props.hideModal}>
            No, cancel
        </Cancel>
        <Remove onClick={removeContact} processing={processing}>
            Yes, delete
        </Remove>
      </ButtonGroup>
    </div>
  )
}


DeleteContactConfirmation.propTypes = {
  contactId: PropTypes.string,
  contactLabel: PropTypes.string,
  deleteResource: PropTypes.func,
  hideModal: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default DeleteContactConfirmation
