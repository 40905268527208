import PropTypes from 'prop-types'

import {Button} from '../../blocks/Buttons'
import {TextGutterMedium} from '../../blocks/Texts'
import Closer from '../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../shared_components/core/modals/Modals'

import {addPossessive} from '../../../../../lib/tools'
import successCheckmarkIcon from '../../../../../shared_assets/v2/success-checkmark.svg'


const DeputyAddedConfirmation = props => (
  <Modals.SuccessPopUpModal showModalOnMount closerComponent={() => <Closer closer={props.exit} />}>
    <header>
      <img src={successCheckmarkIcon} alt='Success checkmark' />
      <h2>Your deputy invitation is on its way.</h2>
    </header>
    <TextGutterMedium>We'll let you know when {props.firstName} accepts your invitation.</TextGutterMedium>
    <TextGutterMedium>
      <strong>Note:</strong> To ensure that your invitation doesn't get lost in {addPossessive(props.firstName)} inbox, we suggest
          following up with {props.firstName} on your own to make sure the invitation is viewed.
    </TextGutterMedium>
    <Button onClick={props.exit}>Done</Button>
  </Modals.SuccessPopUpModal>
)

DeputyAddedConfirmation.propTypes = {
  firstName: PropTypes.string,
  exit: PropTypes.func
}

export default DeputyAddedConfirmation
