import {List} from 'immutable'
import PropTypes from 'prop-types'

import ItemWithResponsesBox from './ItemWithResponsesBox'

import {itemResponsesWithResponseGroupMap} from '../../../../../lib/plan_data/itemResponsesHelper'

import './itemsWithResponses.scss'

const ItemsWithResponses = props => {
  const itemResponses = itemResponsesWithResponseGroupMap(props.categoryItemResponses)

  if (itemResponses.isEmpty()) {
    return null
  } else {
    return (
      <div className='items-with-responses'>
        <h6 className='heading'>In your Everplan</h6>
        {
          itemResponses
            .map(itemResponse =>
              (
                <ItemWithResponsesBox
                  {...props}
                  itemResponse={itemResponse}
                  key={itemResponse.get('id')}
                />
              )
            )
        }
      </div>
    )
  }
}

ItemsWithResponses.propTypes = {
  categoryItemResponses: PropTypes.instanceOf(List)
}

export default ItemsWithResponses
