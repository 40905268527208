import {Component} from 'react'
import PropTypes from 'prop-types'

import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import {Cancel, Remove} from '../../../../dashboard/src/components/blocks/Buttons'
import Modals from '../Modals'
import {TextGutterLarge} from '../../../../dashboard/src/components/blocks/Texts'

import './confirmationModal.scss'


export default class ConfirmationModal extends Component {
  render() {
    const {cancelButtonText, confirmationText, confirmButtonText, onConfirm, processing, toggleModal, ...otherProps} = this.props

    return (
      <Modals.LinkPopUpModal className='confirmation-modal' {...otherProps}>
        <TextGutterLarge>
          {confirmationText}
        </TextGutterLarge>
        <ButtonGroup>
          <Cancel onClick={toggleModal}>
            {cancelButtonText}
          </Cancel>
          <Remove onClick={onConfirm} processing={processing}>
            {confirmButtonText}
          </Remove>
        </ButtonGroup>
      </Modals.LinkPopUpModal>
    )
  }
}

ConfirmationModal.defaultProps = {
  cancelButtonText: 'No, cancel',
  confirmationText: '',
  confirmButtonText: 'Yes, continue'
}

ConfirmationModal.propTypes = {
  cancelButtonText: PropTypes.string,
  closerComponent: PropTypes.func,
  confirmationText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.func,
  processing: PropTypes.bool,
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func
}
