import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Map} from 'immutable'
import {readEndpoint} from 'redux-json-api'
import {useEffect} from 'react'
import {useQuery} from '@apollo/client'
import PropTypes from 'prop-types'

import Loader from '../../../../shared_components/NewLoader'

import {CONTACTS} from '../../../../graphql/queries/userData'
import {currentUserConfig} from '../../../../lib/userTools'
import {DEPUTIES, SPECIFIC_ITEMS_DEPUTIES, DEPUTIZERS} from '../../../../graphql/queries/deputy'
import {ITEM_RESPONSES} from '../../../../graphql/queries/itemResponse'
import {useNotificationContext} from '../../../../shared_components/notifications/NotificationContext'
import * as actionCreators from './actions/corpMaxActionCreators'
import routerUtils from '../../../../lib/routerUtils'


const CorpMaxController = props => {
  const {alwaysNotify} = useNotificationContext()
  const everplanId = props.userConfig?.get('everplan-id')

  const {loading: loadingItemResponses, refetch} = useQuery(ITEM_RESPONSES, {variables: {everplanId}})
  const {loading: loadingContacts} = useQuery(CONTACTS, {variables: {everplanId}})
  const {loading: loadingDeputies} = useQuery(DEPUTIES, {variables: {everplanId}})
  const {loading: loadingSpecificItemsDeputies} = useQuery(SPECIFIC_ITEMS_DEPUTIES)
  useQuery(DEPUTIZERS, {variables: {userId: parseInt(props.userConfig?.get('id'), 10)}})

  const fetchResponseAndNotifyUserAfterMigration = () => {
    refetch().then(() => {
      alwaysNotify.longSuccess('All files have been encrypted. You can now access your files and documents.')
    })
  }


  // We need this to be here since this is the top-most container for CorpMax
  // and we need the polling/notification about file migration to happen
  // irrespective of where a user is in CorpMax - Kay
  const pollFileMigrationStatus = () => {
    const jobId = setInterval(() => {
      props.readEndpoint(`everplans/${everplanId}`)
        .then(everplan => {
          if (everplan.body.data.attributes['pending-migration-file-count'] === 0) {
            clearInterval(jobId)
            fetchResponseAndNotifyUserAfterMigration()
          }
        })
    }, 15000)
  }

  useEffect(() => {
    const userConfig = props.userConfig

    if (userConfig.get('client'))
      props.readEndpoint(`everplans/${everplanId}?include=firm-ownership,firm.firm-config,firm.organization`)

    props.readEndpoint(`item-views?filter[everplan_id]=${everplanId}`)
    props.readEndpoint(`everplans/${everplanId}?include=owners`)
      .then(everplanApiData => {
        props.corpMaxActions.reshapeOwners(everplanApiData.body.included.filter(included => included.type === 'owners'))
        if (everplanApiData.body.data.attributes['pending-migration-file-count'] > 0)
          pollFileMigrationStatus()
      })
  }, [])

  const loading = props.loading || loadingDeputies || loadingSpecificItemsDeputies || loadingItemResponses || loadingContacts

  return (
    <div className='corp-max-controller'>
      <Loader loading={loading}>
        {props.children}
      </Loader>
    </div>
  )
}

const isNotLoadingCorpMaxData = state => (
  state.api.plans &&
  state.api.users &&
  state.api['user-configs'] &&
  state.api.everplans &&
  state.api.settings &&
  !state.owners.isEmpty()
)


export const mapStateToProps = (state, {location}) => {
  const userConfig = currentUserConfig(state.api)

  if (!userConfig.isEmpty() && (location.pathname === '/cm' || location.pathname === '/cm/'))
    return routerUtils.push('cm/dashboard')

  return ({
    loading: !isNotLoadingCorpMaxData(state),
    userConfig
  })
}

const mapDispatchToProps = dispatch => ({
  corpMaxActions: bindActionCreators(actionCreators, dispatch),
  readEndpoint: bindActionCreators(readEndpoint, dispatch)
})

CorpMaxController.propTypes = {
  readEndpoint: PropTypes.func,
  createResource: PropTypes.func,
  updateResource: PropTypes.func,
  corpMaxActions: PropTypes.shape({
    reshapeOwners: PropTypes.func
  }),
  router: PropTypes.shape({
    isActive: PropTypes.func
  }),
  userConfig: PropTypes.instanceOf(Map)
}


export default connect(mapStateToProps, mapDispatchToProps)(CorpMaxController)
