import PropTypes from 'prop-types'

import {Cancel, Remove} from '../blocks/Buttons'
import {TextGutterMedium} from '../blocks/Texts'
import ButtonGroup from '../../../../shared_components/core/buttons/ButtonGroup'
import Closer from '../../../../shared_components/core/closer/Closer'
import Modals from '../../../../shared_components/core/modals/Modals'

import warningIcon from '../../../../shared_assets/v2/warning.svg'

const RemoveDeputyModal = props => (
  <Modals.WarningPopUpModal showModalOnMount closerComponent={() => <Closer closer={props.exit} />}>
    <header>
      <img src={warningIcon} alt='Warning' />
      <h2>Are you sure you want to remove {props.deputyName} as your Deputy?</h2>
      <TextGutterMedium>{props.deputyName} will no longer have access to your Everplan.</TextGutterMedium>
    </header>
    <ButtonGroup>
      <Cancel onClick={props.exit}>No, go back</Cancel>
      <Remove onClick={props.removeDeputy} processing={props.processing}>Yes, remove</Remove>
    </ButtonGroup>
  </Modals.WarningPopUpModal>
)

RemoveDeputyModal.propTypes = {
  deputyName: PropTypes.string,
  exit: PropTypes.func,
  processing: PropTypes.bool,
  removeDeputy: PropTypes.func
}

export default RemoveDeputyModal
