import {Map, List} from 'immutable'
import {useState, Fragment} from 'react'
import PropTypes from 'prop-types'

import Logger from '../../../../../lib/NewLogger'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import Modals from '../../../../../shared_components/core/modals/Modals'
import Closer from '../../../../../shared_components/core/closer/Closer'
import ManageExistingPermissions from './ManageExistingPermissions'
import ManageSelectedDeputiesPermission from './ManageSelectedDeputiesPermission'
import NoExistingPermissions from './NoExistingPermissions'
import SelectDeputies from './SelectDeputies'

import {formatExistingDeputiesPermission, formatSelectedDeputiesPermission} from '../../../../../lib/deputyPermissionTools'
import {itemResponseGroupOwners} from '../../../../../lib/plan_data/itemResponsesHelper'

import routerUtils from '../../../../../lib/routerUtils'

import './managePermissionsModal.scss'

const ManagePermissionsModal = props => {
  const [selectedDeputies, setSelectedDeputies] = useState(List())

  const updateExistingPermissions = newPermissions => {
    const updatedDeputiesPermissions = formatExistingDeputiesPermission({newPermissions, deputiesWithPermission: props.deputiesWithPermission})

    props.updatePermissions(updatedDeputiesPermissions)
  }

  const updatePermissionsInBulk = ({selectedPermissionType}) => {
    const {itemResponse, deputiesWithPermission, firstGroupId} = props

    const updatedDeputiesPermissions = formatSelectedDeputiesPermission({
      deputiesWithPermission,
      firstGroupId,
      itemResponse,
      selectedDeputies,
      selectedPermissionType
    })

    props.updatePermissions(updatedDeputiesPermissions)
  }

  const addNewDeputyLink = () => {
    Logger.log({
      name: 'clicked_add_new_deputy',
      payload: {
        context: 'deputy_permissions_single_item',
        item: props.itemResponse.get('name')
      }
    })

    routerUtils.push('cm/deputies/new')
  }

  const sharedProps = {
    ...props,
    addNewDeputyLink,
    selectedDeputies,
    setSelectedDeputies
  }

  const modalSubheading = props.itemResponse.get('is-household') ?
    `Belongs to ${itemResponseGroupOwners(props.responseGroup)}` :
    null

  return (
    <Modals.PopUpModalLarge className='manage-permissions-modal' showModal closerComponent={() => <Closer closer={props.toggleModal} />}>
      {
        <Fragment>
          <ModalHeader
            heading={props.keyField}
            banner={'Manage permissions'}
            subheading={modalSubheading}
          />
          {
            props.hasNoPermissions ?
              <NoExistingPermissions {...sharedProps} onSubmit={updatePermissionsInBulk} /> :
              <Fragment>
                <SelectDeputies {...sharedProps} />
                {
                  selectedDeputies.isEmpty() ?
                    <ManageExistingPermissions {...sharedProps} onSubmit={updateExistingPermissions} /> :
                    <ManageSelectedDeputiesPermission {...sharedProps} onSubmit={updatePermissionsInBulk} />
                }
              </Fragment>
          }
        </Fragment>
      }
    </Modals.PopUpModalLarge>
  )
}

ManagePermissionsModal.propTypes = {
  deputies: PropTypes.instanceOf(List),
  deputiesWithPermission: PropTypes.instanceOf(List),
  firstGroupId: PropTypes.string,
  hasNoPermissions: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map),
  keyField: PropTypes.instanceOf(List),
  responseGroup: PropTypes.instanceOf(Map),
  toggleModal: PropTypes.func,
  updatePermissions: PropTypes.func
}

export default ManagePermissionsModal
