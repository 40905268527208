import {useMutation} from '@apollo/client'

import {useNotificationContext} from '../shared_components/notifications/NotificationContext'
import {CREATE_SCORE_LEDGER_EVENT} from '../graphql/mutations/userData'


const CRITICAL_DOCUMENT_IDS = [
  'c2e9c420-e330-4b17-aa06-39ffa9e30b98',
  '3b62673a-71aa-4e9d-9ad0-1da754bc1bd8',
  'bee55c5e-b84e-45f7-a297-aad140cb7f7a',
  '21e2ff20-75a9-47dd-93ba-3295fef14066',
  'd16d432f-1290-4346-ba0a-391a612c921b',
  'a2b886bd-4de1-4d62-9e28-00c1c0c5db05',
  '6f10f9bc-7638-4696-b433-37ec1d1834c7',
  '2e669719-d3e5-4d0c-9d32-d61c38fe14c3'
]

export const useScoreLedgerEventLogger = userConfig => {
  const {alwaysNotify} = useNotificationContext()
  const [createScoreLedgerEvent] = useMutation(CREATE_SCORE_LEDGER_EVENT)


  const log = ({name, payload = null, notificationType}) => {
    if (userConfig.get('score-enabled') && name) {
      const formattedPayload = payload ? Object.entries(payload).map(([key, value]) => ({name: key, value})) : []

      createScoreLedgerEvent({
        variables: {input: {name, data: formattedPayload}},
        onCompleted: response => {
          const feedbackMessage = response.createScoreLedgerEvent.feedbackMessage
          if (feedbackMessage) {
            const delay = notificationType === 'delayed' ? 6000 : 0
            alwaysNotify.scoreSuccess(feedbackMessage, delay)
          }
        }
      })
    }
  }

  const logger = notificationType => ({
    addedResponseGroup(payload) {
      log({name: 'added_response_group_from_vault', payload, notificationType})
    },
    addedCriticalDocument(payload) {
      log({name: 'added_critical_document_response_group_from_vault', payload, notificationType})
    },
    updatedResponseGroup(payload) {
      log({name: 'updated_response_group_from_vault', payload, notificationType})
    },
    addedActionItemResponseGroup(payload) {
      log({name: 'added_response_group_from_action_item', payload, notificationType})
    },
    addedActionItemCriticalDocument(payload) {
      log({name: 'added_critical_document_response_group_from_action_item', payload, notificationType})
    },
    readActionItemGuide(payload) {
      log({name: 'read_action_item_guide', payload, notificationType})
    },
    clickedActionItemVendor(payload) {
      log({name: 'clicked_action_item_vendor', payload, notificationType})
    },
    downloadedActionItemForm(payload) {
      log({name: 'downloaded_action_item_form', payload, notificationType})
    },
    readInAppArticle(payload) {
      log({name: 'read_in_app_article', payload, notificationType})
    },
    addedDeputy() {
      log({name: 'added_deputy', notificationType})
    },
    addedDeputyFromPrompt() {
      log({name: 'added_deputy_from_prompt', notificationType})
    },
    updatedResponseGroupPermissions(payload) {
      log({name: 'updated_response_group_permissions', payload, notificationType})
    },
    updatedDeputyPermissions() {
      log({name: 'updated_deputy_permissions', notificationType})
    },
    completedProgram(payload) {
      log({name: 'completed_program', payload, notificationType})
    }
  })

  const logNewResponseGroupWithDelayedNotification = ({itemResponse, isNextBestAction, notificationType = null}) => {
    if (userConfig.get('score-enabled')) {
      const payload = {item: itemResponse.get('header')}
      const isCriticalDocument = CRITICAL_DOCUMENT_IDS.includes(itemResponse.get('item-id'))
      const eventLogger = logger(notificationType)

      if (isCriticalDocument)
        isNextBestAction ? eventLogger.addedActionItemCriticalDocument(payload) : eventLogger.addedCriticalDocument(payload)
      else
        isNextBestAction ? eventLogger.addedActionItemResponseGroup(payload) : eventLogger.addedResponseGroup(payload)
    }
  }

  return {
    logWithDelayedNotification: logger('delayed'),
    logWithNotification: logger(),
    logExistingResponseGroupWithDelayedNotification: ({itemResponse}) => logger('delayed').updatedResponseGroup({item: itemResponse.get('header')}),
    logNewResponseGroupWithDelayedNotification: ({itemResponse, isNextBestAction}) => logNewResponseGroupWithDelayedNotification({itemResponse, isNextBestAction, notificationType: 'delayed'}),
    logNewResponseGroupWithNotification: ({itemResponse, isNextBestAction}) => logNewResponseGroupWithDelayedNotification({itemResponse, isNextBestAction})
  }
}
