import PropTypes from 'prop-types'
import {useRef, useState} from 'react'
import {fromJS, Map, List} from 'immutable'

import {Button, Cancel} from '../../blocks/Buttons'
import {TextGutterMedium} from '../../blocks/Texts'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import DeputyWaitTimeSelect from './DeputyWaitTimeSelect'
import Form from '../../../../../shared_components/forms/v2/Form'
import NoUnlockPermissionInformation from './NoUnlockPermissionInformation'
import UnorderedList from '../../../../../shared_components/UnorderedList'
import VerticalRadioList from '../../../../../shared_components/forms/v2/fields/VerticalRadioList'

import {verificationPeriodStringToMinutes} from '../../../../../lib/deathVerificationPeriodTools'
import validators from '../../../../../shared_components/forms/v2/validators'

import './deputyUnlockPermission.scss'

const defaultRadioSelection = deputy => {
  if (deputy.get('death-reporter'))
    return 'yes'
  else if (deputy.get('death-reporter') === false)
    return 'no'
  else
    return ''
}

const DeputyUnlockPermission = props => {
  const formRef = useRef()

  const [deathReporter, setDeathReporter] = useState(defaultRadioSelection(props.deputy))
  const [disabledButton, setDisabledButton] = useState(!props.deputy.has('death-reporter')) // This method checks if the deputy object has "hasUnlockPermission" key and not it's value - Atanda
  const [showMessage, setShowMessage] = useState(false)

  const handleChange = () => {
    setDisabledButton(false)

    if (formRef.current.formData().getIn(['permissions', 'value']) === 'yes') {
      setDeathReporter('yes')
      setShowMessage(false)
    } else {
      setDeathReporter('no')
      setShowMessage(true)
    }
  }

  const onSubmit = () => {
    const formData = formRef.current.formData()
    const deathReporterValue = formData.getIn(['permissions', 'value']) === 'yes'

    props.submit({
      'death-reporter': deathReporterValue,
      'death-verification-period': deathReporterValue ? verificationPeriodStringToMinutes(formData.getIn(['unordered-list-select', 'value'])) : null
    })
  }

  const shouldShowNoUnlockerPermissionInformation = showMessage || (!props.deputy.get('death-reporter') && (deathReporter === 'no'))

  return (
    <div className='deputy-unlock-permission'>
      {props.children}
      <TextGutterMedium key={deathReporter}>
        <Form className='core' ref={formRef}>
          <VerticalRadioList
            data={
              fromJS({
                id: 'permissions',
                items: [
                  {label: `Allow ${props.deputy.get('first-name')} to unlock my "after death" items`, value: 'yes'}
                ],
                name: 'allow',
                value: deathReporter
              })
            }
            onChange={handleChange}
            validator={validators.required}
          />
          <UnorderedList
            className='additional-info'
            children={
              [
                `${props.deputy.get('first-name')} will be responsible for logging into Everplans after your death and requesting that your "after death" items be unlocked.`,
                'Once unlocked, your "after death" items will be unlocked for all deputies who have been granted "after death" access.'
              ]}
          />
          <DeputyWaitTimeSelect
            deputy={props.deputy}
            displayWaitTimeSelect={deathReporter === 'yes' && !showMessage}
          />
          <VerticalRadioList
            data={
              fromJS({
                id: 'permissions',
                items: [
                  {label: 'Do not allow', value: 'no'}
                ],
                name: 'do-not-allow',
                value: deathReporter
              })
            }
            onChange={handleChange}
            validator={validators.required}
          />
          <NoUnlockPermissionInformation deputies={props.deputies} displayInfo={shouldShowNoUnlockerPermissionInformation} />
        </Form>
      </TextGutterMedium>
      <ButtonGroup>
        <Cancel onClick={props.cancel}>{props.cancelButtonText}</Cancel>
        <Button disabled={disabledButton} processing={props.processing} onClick={onSubmit}>
          {props.buttonText}
        </Button>
      </ButtonGroup>
    </div>
  )
}


DeputyUnlockPermission.defaultProps = {
  buttonText: 'Continue',
  cancelButtonText: 'Back',
  processing: false
}

DeputyUnlockPermission.propTypes = {
  buttonText: PropTypes.string,
  cancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  deputies: PropTypes.instanceOf(List),
  deputy: PropTypes.instanceOf(Map),
  processing: PropTypes.bool,
  submit: PropTypes.func
}

export default DeputyUnlockPermission
