import {Component} from 'react'
import {Map} from 'immutable'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Closer from '../../..//shared_components/core/closer/Closer'
import ConfirmationModal from '../../../shared_components/core/modals/components/ConfirmationModal'

import './wizardCloser.scss'

export default class WizardCloser extends Component {
  constructor() {
    super()
    this.state = {showModal: false}

    this.closer = this.closer.bind(this)
    this.saveWizardResponses = this.saveWizardResponses.bind(this)
    this.discardWizardResponses = this.discardWizardResponses.bind(this)
    this.exitWizard = this.exitWizard.bind(this)
    this.returnToWizard = this.returnToWizard.bind(this)
  }

  closer() { return <Closer closer={this.returnToWizard} /> }

  returnToWizard() {
    this.props.logEvent({name: 'returned_to_add_new'})
    this.setState({showModal: false})
  }

  exitWizard() {
    if (this.props.responses.isEmpty()) {
      this.props.logEvent({name: 'closed_add_new'})
      this.props.exit()
      // Don't want to toggle the modal if there are any files being uploaded or if the responses are submitted and processing
    } else if (!this.disableWizardCloser()) {
      this.setState({showModal: true})
      this.props.logEvent({name: 'viewed_exit_confirmation_modal'})
    }
  }

  discardWizardResponses() {
    this.props.logEvent({name: 'discarded_add_new'})
    this.props.exit()
  }

  saveWizardResponses() {
    this.props.logEvent({name: 'clicked_save_and_exit'})
    this.props.submit()
  }

  disableWizardCloser() {
    return this.props.uploadingFiles || this.props.processing
  }

  render() {
    return (
      <div className='wizard-closer'>
        <Closer className={classnames({disabled: this.disableWizardCloser()})} closer={this.exitWizard} />
        <ConfirmationModal
          closerComponent={this.closer}
          confirmationText='Are you sure you want to close this window? You have unsaved changes.'
          onConfirm={this.saveWizardResponses}
          confirmButtonText='Save and Exit'
          cancelButtonText='Discard My Info'
          showModal={this.state.showModal}
          toggleModal={this.discardWizardResponses}
          processing={this.props.processing}
        />
      </div>
    )
  }
}


WizardCloser.propTypes = {
  exit: PropTypes.func.isRequired,
  logEvent: PropTypes.func,
  processing: PropTypes.bool,
  responses: PropTypes.instanceOf(Map),
  submit: PropTypes.func,
  uploadingFiles: PropTypes.bool
}
