import {gql} from '@apollo/client'

import {SCORE_ASSESSMENT_FRAGMENT} from '../fragments/scoreAssessment'

export const CMS = gql`
  ${SCORE_ASSESSMENT_FRAGMENT}
  query Cms {
    cms {
      scoreAssessment {...ScoreAssessmentFragment}
    }
  }
`
