import PropTypes from 'prop-types'
import {useState} from 'react'

import {capitalize} from '../../../../../lib/tools'
import {TextGutterLarge} from '../../blocks/Texts'
import Modals from '../../../../../shared_components/core/modals/Modals'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import {Remove, Cancel} from '../../blocks/Buttons'
import Closer from '../../../../../shared_components/core/closer/Closer'


const CancelSubscription = ({periodEnd, cancelAutoSubscription, cancelText, isTrialing}) => {
  const [showModal, setShowModal] = useState(false)
  const actionText = isTrialing ? 'activation' : 'renewal'

  const closer = () => <Closer closer={() => setShowModal(false)} />

  const handleCancelSubscription = () => {
    cancelAutoSubscription()
    setShowModal(false)
  }

  return (
    <div className='cancel-subscription'>
      <a onClick={() => setShowModal(true)}>{cancelText}</a>
      <Modals.WarningPopUpModal closerComponent={closer} showModal={showModal}>
        <header>
          <h2>Turn Off Auto-{capitalize(actionText)}</h2>
        </header>
        <TextGutterLarge>
          Are you sure that you want to turn off auto-{actionText.toLowerCase()}? You'll no longer
          be billed automatically, and your Everplans Premium subscription will expire on{' '}
          {periodEnd}.
        </TextGutterLarge>
        <ButtonGroup>
          <Cancel onClick={() => setShowModal(false)} />
          <Remove onClick={handleCancelSubscription}>
            Turn off auto-{actionText}
          </Remove>
        </ButtonGroup>
      </Modals.WarningPopUpModal>
    </div>
  )
}

CancelSubscription.propTypes = {
  isTrialing: PropTypes.bool,
  periodEnd: PropTypes.string,
  cancelText: PropTypes.string,
  cancelAutoSubscription: PropTypes.func
}

export default CancelSubscription
