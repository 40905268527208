import {gql} from '@apollo/client'

export const CONTENT_SCREEN_FRAGMENT = gql`
  fragment ContentScreenFragment on ScoreAssessmentContentScreen {
    id
    alternateBody
    body
    name
    sortOrder
  }
`

export const SCREEN_OPTION_FRAGMENT = gql`
  fragment ScreenOptionFragment on ScoreAssessmentScreenOption {
    id
    label
    value
    ignoreValue
    nextScreen
    multiSelect
    weight
  }
`

export const ASSESSMENT_SCREEN_FRAGMENT = gql`
  ${SCREEN_OPTION_FRAGMENT}
  fragment AssessmentScreenFragment on ScoreAssessmentScreen {
    id
    alternateBody
    body
    maximumSelections
    name
    sortOrder
    optionsType
    options {...ScreenOptionFragment}
  }
`

export const SCORE_ASSESSMENT_FRAGMENT = gql`
  ${ASSESSMENT_SCREEN_FRAGMENT}
  ${CONTENT_SCREEN_FRAGMENT}
  fragment ScoreAssessmentFragment on ScoreAssessment {
    id
    name
    assessmentScreens {...AssessmentScreenFragment}
    contentScreens {...ContentScreenFragment}
  }
`
