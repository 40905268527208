import {useState} from 'react'
import PropTypes from 'prop-types'

import ConfirmationModal from '../../../core/modals/components/ConfirmationModal'
import Closer from '../../../core/closer/Closer'

import './gapFillerAssessmentCloser.scss'

const GapFillerAssessmentCloser = ({
  toggleGapFillerAssessmentModal,
  confirmationText
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const confirmationModalCloser = () => (
    <Closer onClick={() => setShowConfirmationModal(false)} />
  )

  return (
    <div className='gap-filler-assessment-closer'>
      <Closer closer={() => setShowConfirmationModal(true)} />
      <ConfirmationModal
        closerComponent={confirmationModalCloser}
        confirmationText={confirmationText}
        onConfirm={toggleGapFillerAssessmentModal}
        confirmButtonText='Yes, close'
        cancelButtonText='No, go back'
        showModal={showConfirmationModal}
        toggleModal={() => setShowConfirmationModal(false)}
      />
    </div>
  )
}


GapFillerAssessmentCloser.defaultProps = {
  confirmationText: 'Are you sure you want to exit this assessment?'
}

GapFillerAssessmentCloser.propTypes = {
  toggleGapFillerAssessmentModal: PropTypes.func,
  confirmationText: PropTypes.string
}


export default GapFillerAssessmentCloser
