/** @class DeleteContact
 *
 * @desc
 * Component used to display an icon that, when clicked, opens a confirmation modal
 * from which either a given contact can be deleted or the user is informed about
 * why a contact cannot be deleted.
 *
 * @todo Fix up styling once final mocks are in place.
 * @todo Wire up logging of various types.
 */

import {useState} from 'react'
import {List} from 'immutable'
import PropTypes from 'prop-types'

import Closer from '../../../../../shared_components/core/closer/Closer'
import DeleteIcon from '../../../../../shared_assets/v2/DeleteIcon'
import Modals from '../../../../../shared_components/core/modals/Modals'
import DeleteContactConfirmation from './DeleteContactConfirmation'
import UndeletableContactModalContents from './UndeletableContactModalContents'

import './deleteContact.scss'

// Colors from the style guide:
const headerTextColor = '#262626'
const inputDisabledPlaceholderText = '#A8A8A8'


const DeleteContact = props => {
  const [showModal, setShowModal] = useState(false)

  const hideModal = () => { setShowModal(false) }

  const closer = () => <Closer closer={hideModal} />

  return (
    <div className='delete-contact flex-child flex-container'>
      <a onClick={() => setShowModal(true)}>
        <DeleteIcon
          alt={`Delete ${props.contactLabel} as a contact`}
          fill={props.contactItems.isEmpty() ? headerTextColor : inputDisabledPlaceholderText}
          height='15'
          width='15'
        />
      </a>
      {
        // Using this because the way the `BaseModal` works doesn't actually keep the modal
        // off the DOM completely but rather visually hides it. --BLR
        showModal &&
          (
            <Modals.LinkPopUpModal closerComponent={closer} showModal={true}>
              <h2>Delete {props.contactLabel}</h2>
              {
                props.contactItems.isEmpty() ?
                  <DeleteContactConfirmation {...props} hideModal={hideModal} /> :
                  <UndeletableContactModalContents contactItems={props.contactItems} />
              }
            </Modals.LinkPopUpModal>
          )
      }
    </div>
  )
}

DeleteContact.propTypes = {
  contactId: PropTypes.string,
  contactItems: PropTypes.instanceOf(List),
  contactLabel: PropTypes.string
}

export default DeleteContact
