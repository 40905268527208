import colors from '../../../../../../dashboard/styles/abstracts/colors.module.scss'

const styles = {
  control: baseStyles => ({
    ...baseStyles,
    border: `1px solid ${colors.inputBorder}`,
    borderRadius: '8px',
    cursor: 'pointer',
    padding: '7px 16px',
    width: '142px'
  }),
  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    color: colors.bodyText,
    padding: '0'
  }),
  indicatorContainer: baseStyles => ({
    ...baseStyles,
    padding: '0'
  }),
  indicatorSeparator: baseStyles => ({
    ...baseStyles,
    display: 'none'
  }),
  menu: baseStyles => ({
    ...baseStyles,
    border: `1px solid ${colors.inputBorder}`,
    borderRadius: '8px',
    boxShadow: 'none',
    left: '-25%',
    width: '208px'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: state.isFocused ? '#FFFFFF' : colors.headerText,
    backgroundColor: state.isFocused ? colors.buttonHoverBackground : 'transparent',
    fontSize: '0.9375rem',
    lineHeight: '160%',
    padding: state.isSelected ? '6px 32px' : '6px 32px 6px 60px'
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: colors.bodyText,
    fontSize: '0.9375rem',
    lineHeight: '18px',
    margin: '0'
  }),
  valueContainer: baseStyles => ({
    ...baseStyles,
    padding: '0'
  })
}

export default styles
