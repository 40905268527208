import {Fragment, useCallback, useRef, useState} from 'react'

import {fromJS, Map} from 'immutable'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {deleteResource} from 'redux-json-api'

import routerUtils from '../../../../../lib/routerUtils'
import {accountSettingsUrl, railsUrl} from '../../../../../lib/urlTools'
import {currentUserConfig} from '../../../../../lib/userTools'
import {Cancel, Remove} from '../../blocks/Buttons'
import {TextGutterMedium, TextGutterSmall} from '../../blocks/Texts'
import currentUserWrapper from '../../../../../shared_components/currentUserWrapper'
import Closer from '../../../../../shared_components/core/closer/Closer'
import Modals from '../../../../../shared_components/core/modals/Modals'
import Form from '../../../../../shared_components/forms/v2/Form'
import validators from '../../../../../shared_components/forms/v2/validators'
import Fields from '../../../../../shared_components/forms/v2/fields/index'

import './deleteAccountModal.scss'

export const DeleteAccountModal = props => {
  const [isProcessing, setIsProcessing] = useState(false)

  const form = useRef(null)

  const deleteAccount = useCallback(() => {
    form.current.onSubmit()

    if (form.current.isValid()) {
      setIsProcessing(true)

      props.deleteResource({
        type: 'users',
        id: props.currentUser.id
      }).then(() => (
        routerUtils.setLocation(railsUrl('sign_out'))
      ))
    }
  }, [form, props.currentUser])

  const handleClosing = useCallback(() => {
    routerUtils.setLocation(accountSettingsUrl)
  }, [props.currentUser])

  const isAdvisor = useCallback(() => {
    const {userConfig} = props

    return !!(userConfig.get('firm-id') && !userConfig.get('client'))
  }, [props.userConfig])

  const modalCloser = useCallback(
    () => (
      <Closer
        closer={handleClosing}
      />
    ), [handleClosing]
  )

  return (
    <Modals.PopUpModalLarge
      closerComponent={modalCloser}
      className='delete-account-modal'
      showModal={true}
      showModalOnMount={true}>
      {isAdvisor() ?
        <p>
          Your account cannot be deleted because you are an advisor. Please
          contact your account manager for assistance.
        </p> :
        <Fragment>
          <TextGutterMedium>
            Are you sure you want to delete your account?
          </TextGutterMedium>
          <TextGutterMedium>
            This will permanently delete ALL items, documents, information you have saved and <strong>permanently</strong> delete your account.
          </TextGutterMedium>
          <div className='forms-playground'>
            <Form className='core' ref={form}>
              <TextGutterSmall className='delete-instructions-text'>
                To proceed, type the word <strong>delete</strong> into the box
                below:
              </TextGutterSmall>
              <Fields.Text
                data={fromJS({
                  id: 'delete-verification',
                  name: 'delete-verification'
                })}
                validator={validators.deleteAccountValidator}
              />
            </Form>

            <Cancel onClick={handleClosing} />
            <Remove
              onClick={deleteAccount}
              processing={isProcessing}>
                Permanently delete account
            </Remove>
          </div>
        </Fragment>
      }
    </Modals.PopUpModalLarge>
  )
}

DeleteAccountModal.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number
  }),
  deleteResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = ({api}) => {
  const userConfig = currentUserConfig(api)

  return {userConfig}
}

export default currentUserWrapper(connect(mapStateToProps, {deleteResource})(DeleteAccountModal))
