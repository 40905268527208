import PropTypes from 'prop-types'
import {Map} from 'immutable'
import {useState} from 'react'

import {TextGutterSmall} from '../../blocks/Texts'
import CancelSubscription from './CancelSubscription'
import Coupon from './Coupon'
import Loader from '../../../../../shared_components/NewLoader'

import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import {upcomingInvoiceTextWithCoupon} from '../../../../../lib/subscriptionTools'

const AutoSubscription = ({
  billing,
  userConfig,
  isTrialing,
  noSubscriptionRenewal,
  periodEnd,
  updateResource
}) => {
  const [processing, setProcessing] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const updateSubscription = status => {
    const resource = {
      type: 'billings',
      id: userConfig.get('everplan-id'),
      attributes: {'auto-renewal': status}
    }
    const action = status ? 'on' : 'off'
    const actionText = isTrialing ? 'activation' : 'renewal'

    setProcessing(true)
    updateResource(resource)
      .then(() => alwaysNotify.shortSuccess(`Auto-${actionText} has been turned ${action}`))
      .catch(() => alwaysNotify.shortError(`Unable to turn ${action} auto-${actionText}`))
      .finally(() => setProcessing(false))
  }

  const turnOnAutoSubscription = () => updateSubscription(true)
  const cancelAutoSubscription = () => updateSubscription(false)

  if (processing) return <Loader loading={true} />

  if (noSubscriptionRenewal) {
    return (
      <div>
        <TextGutterSmall>Disabled.</TextGutterSmall>
        <a onClick={turnOnAutoSubscription}>Turn on</a>
      </div>
    )
  } else {
    const actionText = isTrialing ? 'activated' : 'renewed'
    const cancelText = isTrialing ? 'Cancel Auto-Activation' : 'Cancel Auto-Renewal'
    const upcomingInvoicePrice = billing.get('upcoming-invoice-price')
    const couponApplied = billing.get('coupon')

    return (
      <div className='auto-subscription'>
        <TextGutterSmall>Enabled.</TextGutterSmall>
        <TextGutterSmall>
          Your account will be {actionText} on {periodEnd}.
        </TextGutterSmall>
        {
          upcomingInvoicePrice && (
            <TextGutterSmall>
              {upcomingInvoiceTextWithCoupon({couponApplied, upcomingInvoicePrice})}
            </TextGutterSmall>
          )
        }
        <Coupon
          updateResource={updateResource}
          userConfig={userConfig}
        />
        <CancelSubscription
          isTrialing={isTrialing}
          periodEnd={periodEnd}
          cancelText={cancelText}
          cancelAutoSubscription={cancelAutoSubscription}
        />
      </div>
    )
  }
}

AutoSubscription.propTypes = {
  billing: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map),
  isTrialing: PropTypes.bool,
  noSubscriptionRenewal: PropTypes.bool,
  periodEnd: PropTypes.string,
  updateResource: PropTypes.func
}

export default AutoSubscription
