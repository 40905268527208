import {Map} from 'immutable'
import {useFormContext, Controller} from 'react-hook-form'
import {useMemo} from 'react'
import PropTypes from 'prop-types'

import {BoxGutterSmall} from '../../blocks/Boxes'
import {existingPermissionOptions} from '../../../../../lib/deputyPermissionTools'
import {itemResponseGroupOwners} from '../../../../../lib/plan_data/itemResponsesHelper'
import PermissionSelect from '../../permissions/specific_item/shared_components/PermissionSelect'

import './manageSpecificItemPermission.scss'

const ManageSpecificItemPermission = props => {
  const {control, watch} = useFormContext()

  const keyField = useMemo(() => {
    const responseKeyField = props.responseGroup.get('key-field')

    return responseKeyField.isEmpty() ? 'Response' : responseKeyField
  }, [props.firstGroupId])

  const values = watch(props.firstGroupId)

  // Update the permission options to include the extra attributes for that permission, but not to override
  // the label, selectedLabel, value. If the options are not updated here, then they will not be returned
  // if a different option from the default is selected.
  const options = useMemo(() => (
    existingPermissionOptions.map(existingPermissionOption => ({...values, ...existingPermissionOption}))
  ), [props.firstGroupId])

  return (
    <BoxGutterSmall className='manage-specific-item-permission'>
      <div className='item-header-keyfield-container'>
        <div className='item-header'>{props.itemResponse.get('header')}</div>
        <div className='key-field'>{keyField}</div>
        {props.itemResponse.get('is-household') &&
          <div className='ownership'>{itemResponseGroupOwners(props.responseGroup)}</div>
        }
      </div>
      <Controller
        name={props.firstGroupId}
        control={control}
        render={({field}) => (
          <PermissionSelect
            {...field}
            classNamePrefix='specific-item-permission-select'
            options={options}
          />
        )}
      />
    </BoxGutterSmall>
  )
}

ManageSpecificItemPermission.propTypes = {
  firstGroupId: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  responseGroup: PropTypes.instanceOf(Map)
}

export default ManageSpecificItemPermission
