import PropTypes from 'prop-types'

import {List, Map} from 'immutable'
import {useState, useMemo} from 'react'

import AddDeputyController from '../corp_max_deputies/add/AddDeputyController'
import ModuleBox from '../../../../shared_components/component_templates/ModuleBox'
import SharingModuleWithDeputies from './with_deputies/SharingModuleWithDeputies'
import SharingModuleWithoutDeputies from './SharingModuleWithoutDeputies'
import {hasDeputyWithDeferredPermissions} from '../../../../lib/deputyPermissionTools'
import {useDeputizerData, useDeputyData} from '../../../../lib/hooks'

import './sharingModuleController.scss'
import Logger from '../../../../lib/NewLogger'
import MixpanelLogger from '../../../../lib/MixpanelLogger'

const SharingModuleController = props => {
  const [showAddDeputyModal, setShowAddDeputyModal] = useState(false)
  const everplanId = props.userConfig.get('everplan-id')
  const {deputies} = useDeputyData(everplanId)
  const {deputizers} = useDeputizerData(props.userConfig)

  const everplanHasResponses = useMemo(() => props.itemResponses.some(itemResponse => !itemResponse.get('response-groups').isEmpty()), [props.itemResponses])
  const hasDeputyWithDeferredItems = useMemo(() => hasDeputyWithDeferredPermissions(deputies), [deputies])

  const openAddDeputyModal = () => {
    MixpanelLogger.track(
      'click_add_deputy',
      {
        context: props.context,
        deputy_count: deputies.size
      }
    )
    Logger.log({
      name: 'clicked_add_new_deputy',
      payload: {context: props.context}
    })
    setShowAddDeputyModal(true)
  }

  return (
    <div className='sharing-module-controller'>
      <h6 className='heading'>Your network</h6>
      <ModuleBox className='module-box'>
        {
          deputies.isEmpty() && deputizers.isEmpty() ?
            <SharingModuleWithoutDeputies openAddDeputyModal={openAddDeputyModal} /> :
            <SharingModuleWithDeputies deputizers={deputizers} deputies={deputies} openAddDeputyModal={openAddDeputyModal} />
        }
        <AddDeputyController
          {...props}
          deputies={deputies}
          everplanHasResponses={everplanHasResponses}
          exit={() => setShowAddDeputyModal(false)}
          hasDeputyWithDeferredItems={hasDeputyWithDeferredItems}
          showModal={showAddDeputyModal}
        />
      </ModuleBox>
    </div>
  )
}

SharingModuleController.propTypes = {
  context: PropTypes.string,
  itemResponses: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

export default SharingModuleController
