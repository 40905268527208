import {List, Map} from 'immutable'
import {useState} from 'react'
import PropTypes from 'prop-types'
import {useMutation} from '@apollo/client'

import Closer from '../../../../../shared_components/core/closer/Closer'
import ConfirmationModal from '../../../../../shared_components/core/modals/components/ConfirmationModal'
import DeleteIcon from '../../../../../shared_assets/v2/DeleteIcon'

import {DELETE_ITEM_RESPONSE_GROUP} from '../../../../../graphql/mutations/itemResponse'
import {DEPUTIES} from '../../../../../graphql/queries/deputy'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import Logger from '../../../../../lib/NewLogger'

import './deleteCompoundResponseGroup.scss'

const headerText = '#515151' // Bring in color from Style Guide. --KTW


const DeleteCompoundResponseGroup = props => {
  const everplanId = props.itemResponse.get('everplan-id')

  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const [deleteItemResponseGroup] = useMutation(DELETE_ITEM_RESPONSE_GROUP, {
    refetchQueries: [{query: DEPUTIES, variables: {everplanId}}],
    onQueryUpdated(observableQuery) {
      if (props.ownEverplan && observableQuery.queryName === 'deputies')
        observableQuery.refetch()
    }
  })

  const logEvent = name => {
    const itemName = props.itemResponse.get('name')
    Logger.log({
      name,
      payload: {
        actor: props.actor,
        context: props.context,
        everplan_id: everplanId,
        item: itemName,
        type: 'vault',
        wildcard: itemName
      }
    })
  }

  const toggleModal = () => {
    showModal ? logEvent('returned_to_response_group') : logEvent('viewed_delete_confirmation_modal')

    setShowModal(prevShowModal => !prevShowModal)
  }

  const deleteResponseGroup = () => {
    setProcessing(true)

    deleteItemResponseGroup({
      variables: {
        input: {
          itemId: props.itemResponse.get('item-id'),
          firstGroupId: props.responseGroup.get('id')
        },
        everplanId
      }, onCompleted: () => {
        alwaysNotify.shortSuccess(props.ownEverplan ?
          'Your information has been deleted.' :
          "Your client's information has been deleted."
        )

        props.exit()
      }
    })
  }

  const onDeleteAll = () => {
    logEvent('clicked_delete_all')
    toggleModal()
  }

  return (
    <div className='delete-compound-response-group'>
      <ConfirmationModal
        closerComponent={() => <Closer closer={toggleModal} />}
        confirmationText={`Are you sure you want to delete ${props.keyField.join('')}? All information associated with it will be removed.`}
        confirmButtonText='Yes, delete'
        onConfirm={deleteResponseGroup}
        processing={processing}
        showModal={showModal}
        toggleModal={toggleModal}
      />
      <div onClick={onDeleteAll} className='delete-responses-trigger flex-child'>
        <DeleteIcon alt={`Delete ${props.keyField}`} fill={headerText} height='15' width='15' />
          Delete All
      </div>
    </div>
  )
}


DeleteCompoundResponseGroup.defaultProps = {
  keyField: List()
}

DeleteCompoundResponseGroup.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  exit: PropTypes.func.isRequired,
  itemResponse: PropTypes.instanceOf(Map),
  keyField: PropTypes.instanceOf(List),
  ownEverplan: PropTypes.bool,
  responseGroup: PropTypes.instanceOf(Map)
}

export default DeleteCompoundResponseGroup
