import classnames from 'classnames'
import {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {List, Map, fromJS} from 'immutable'
import {createResource, readEndpoint} from 'redux-json-api'

import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import FeatureFlags from '../../../../../lib/FeatureFlags'
import AccountSelectionModal from './AccountSelectionModal'
import AccountImportSuccessModal from './AccountImportSuccessModal'
import PlaidLinkButtonContainer from './PlaidLinkButtonContainer'
import {useEverplanData} from '../../../../../lib/hooks'

const ACCOUNT_RETRIEVAL_ERROR = 'We encountered an error while trying to retrieve your accounts. Please try again or add your information manually.'

export const PlaidLinkApp = props => {
  const [accounts, setAccounts] = useState(List())
  const [plaidMetadata, setPlaidMetadata] = useState({})
  const [selectedAccounts, setSelectedAccounts] = useState(List())
  const [showAccountSelectionModal, setShowAccountSelectionModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [plaidUtils, setPlaidUtils] = useState()
  const {alwaysNotify} = useNotificationContext()

  const everplanId = props.userConfig.get('everplan-id')

  const {refetchItemResponses, refetchNewContacts} = useEverplanData(everplanId)

  const closeModal = () => {
    if (props.closeModal) {
      props.closeModal()
    } else {
      setShowAccountSelectionModal(false)
      setShowSuccessModal(false)
    }
  }

  const closeAndNotifyError = message => {
    closeModal()
    alwaysNotify.shortError(message)
  }

  const toggleParentLoading = () => {
    props.toggleParentLoading && props.toggleParentLoading()
  }

  const hasCheckingOrSavingsAccount = metadataAccounts => metadataAccounts.some(account =>
    ['checking', 'savings'].includes(account.subtype)
  )

  /**
   * When an institution has no checkings or savings account we have to reshape the accounts
   * because we won't be hitting the `plaid-items` endpoint which already returns the accounts
   * with the correct shape (i.e keys), the keys we need are `account_number` and `account_id` - TK
   */
  const reshapeAccounts = metadataAccounts => fromJS(
    metadataAccounts.map(account => ({
      ...account,
      account_id: account.id,
      account_number: account.mask
    }))
  )

  /**
   * I'm not sure why we grab the `public_token` from the `metadata` rather than the standalone argument,
   * but I'm hesitant to change it without confirmation from Kay and Tolu about the approach. --BLR
   */
  const onSuccess = (_public_token, metadata) => { // eslint-disable-line no-unused-vars
    const resource = {
      type: 'plaid-items',
      attributes: {data: {public_token: metadata.public_token}}
    }

    setPlaidMetadata(metadata)
    setShowAccountSelectionModal(true)

    if (hasCheckingOrSavingsAccount(metadata.accounts)) {
      props
        .createResource(resource)
        .then(response => {
          setAccounts(fromJS(response.data.attributes.accounts))
          toggleParentLoading()
        })
        .catch(() => {
          closeAndNotifyError(ACCOUNT_RETRIEVAL_ERROR)
        })
    } else if (metadata.accounts) {
      /**
       * still send the token to the server, even though we don't
       * need it client side. We have to clean up the item on the
       * plaid side of things, so we don't keep poling for transactions. --BJK
       */
      props.createResource(resource)
      setAccounts(reshapeAccounts(metadata.accounts))
      toggleParentLoading()
    } else {
      closeAndNotifyError(ACCOUNT_RETRIEVAL_ERROR)
    }
  }

  const submitAccount = selectedPlaidAccounts => {
    setSelectedAccounts(selectedPlaidAccounts)
    const payload = {
      accounts: selectedPlaidAccounts,
      institution: plaidMetadata.institution
    }
    const resource = {
      attributes: {data: {plaid: payload}},
      type: 'auto-populations'
    }

    props
      .createResource(resource)
      .then(() => {
        refetchNewContacts({everplanId})
        refetchItemResponses({everplanId}).then(() => {
          setShowAccountSelectionModal(false)
          setShowSuccessModal(true)
        })
      })
      .catch(() => {
        closeAndNotifyError(
          'We encountered an error while trying to save your accounts. Please try again or add your information manually.'
        )
      })
  }

  const importAnotherAccount = () => {
    // Reset the parent's loading state to `false` so account selection will show again once the user
    // authenticates with another institution:
    toggleParentLoading(List())
    setAccounts(List())
    setPlaidMetadata({})
    setSelectedAccounts(List())
    setShowAccountSelectionModal(false)
    setShowSuccessModal(false)
    plaidUtils.open() // This launches the Plaid account import screen
  }

  // Intentionally not displaying the `_error` for now because Plaid already shows the user feedback about invalid credentials:
  const onExit = (_error, _metadata) => { // eslint-disable-line no-unused-vars
    if (props.closeModal)
      props.closeModal()
  }

  if (!FeatureFlags.isEnabled('plaid')) return null

  return (
  /**
       * Don't remove this className as this allows us to test that this
       * component is rendered otherwise it will try to load the PlaidLink library
       * and fail if the library fails to load for whatever reason - TK
       **/
    <div className='plaid-link-wrapper'>
      {showAccountSelectionModal && (
        <AccountSelectionModal
          accounts={accounts}
          closeModal={closeModal}
          submitAccount={submitAccount}
        />
      )}
      {showSuccessModal && (
        <AccountImportSuccessModal
          closeModal={closeModal}
          importAnotherAccount={importAnotherAccount}
          selectedAccounts={selectedAccounts}
        />
      )}
      {/**
         * This link must always be available on the DOM in order to allow us to click it when importing more accounts.
         * If `PlaidLink` is not available we cannot simulate the user click required to trigger the restart of the
         * Plaid import workflow.
         */}
      <PlaidLinkButtonContainer
        {...props}
        className={classnames('plaid-link', props.className)}
        onClick={toggleParentLoading}
        text={props.text}
        setPlaidUtils={setPlaidUtils}
        onSuccess={onSuccess}
        onExit={onExit}
        style={{backgroundColor: 'transparent'}}
      />
    </div>
  )
}

PlaidLinkApp.propTypes = {
  accounts: PropTypes.instanceOf(List),
  closeModal: PropTypes.func,
  createResource: PropTypes.func,
  isNextBestAction: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  readEndpoint: PropTypes.func,
  text: PropTypes.string,
  toggleParentLoading: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default connect(null, {createResource, readEndpoint})(PlaidLinkApp)
