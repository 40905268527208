import {Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState} from 'react'
import PropTypes from 'prop-types'

import Closer from '../../../../../shared_components/core/closer/Closer'
import ConfirmationModal from '../../../../../shared_components/core/modals/components/ConfirmationModal'

import {DELETE_ITEM_RESPONSE_GROUP_SUBSECTION} from '../../../../../graphql/mutations/itemResponse'
import {DEPUTIES} from '../../../../../graphql/queries/deputy'
import {useNotificationContext} from '../../../../../shared_components/notifications/NotificationContext'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'

import './deleteResponse.scss'


const DeleteResponse = props => {
  const everplanId = props.itemResponse.get('everplan-id')

  const [processing, setProcessing] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const {alwaysNotify} = useNotificationContext()

  const [deleteItemResponseGroupSubsection] = useMutation(DELETE_ITEM_RESPONSE_GROUP_SUBSECTION, {
    refetchQueries: [{query: DEPUTIES, variables: {everplanId}}],
    onQueryUpdated(observableQuery) {
      if (props.ownEverplan && !props.hasMultipleFieldGroups && observableQuery.queryName === 'deputies')
        observableQuery.refetch()
    }
  })

  const logEvent = name => {
    const itemName = props.itemResponse.get('name')

    Logger.log({
      name,
      payload: {
        actor: props.actor,
        context: props.context,
        type: props.eventType,
        item: itemName,
        wildcard: itemName
      }
    })
  }

  const toggleModal = () => {
    showModal ? logEvent('returned_to_response_group') : logEvent('viewed_delete_confirmation_modal')

    setShowModal(prevShowModal => !prevShowModal)
  }

  const deleteResponses = () => {
    setProcessing(true)
    deleteItemResponseGroupSubsection({
      variables: {
        input: {
          itemId: props.itemResponse.get('item-id'),
          groupId: [props.groupId, props.secondResponseGroup.get('second-group-id')],
          kitId: props.currentElement.get('id')
        },
        everplanId
      }, onCompleted: () => {
        alwaysNotify.shortSuccess(props.ownEverplan ?
          'Your information has been deleted.' :
          "Your client's information has been deleted."
        )

        if (props.hasMultipleFieldGroups)
          routerUtils.push(props.viewItemPath({elementId: props.elementId, groupId: props.groupId}))
        else
          props.exit()
      }
    })

    logEvent('clicked_delete_section')
  }

  const onConfirmDelete = () => {
    logEvent('clicked_delete_response_group')
    toggleModal()
  }

  return (
    <div className='delete-response'>
      <div onClick={onConfirmDelete} className='modal-trigger'>{props.launcher}</div>
      <ConfirmationModal
        closerComponent={() => <Closer closer={toggleModal} />}
        confirmationText='Are you sure you want to delete this information?'
        confirmButtonText='Yes, delete'
        onConfirm={deleteResponses}
        processing={processing}
        showModal={showModal}
        toggleModal={toggleModal}
      />
    </div>
  )
}

DeleteResponse.propTypes = {
  actor: PropTypes.string,
  context: PropTypes.string,
  currentElement: PropTypes.instanceOf(Map).isRequired,
  elementId: PropTypes.string,
  eventType: PropTypes.string,
  exit: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  hasMultipleFieldGroups: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map).isRequired,
  launcher: PropTypes.node.isRequired,
  ownEverplan: PropTypes.bool,
  secondResponseGroup: PropTypes.instanceOf(Map),
  viewItemPath: PropTypes.func
}

export default DeleteResponse
