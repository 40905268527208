import PropTypes from 'prop-types'

import Closer from '../../../../../shared_components/core/closer/Closer'
import Logger from '../../../../../lib/NewLogger'
import ConfirmationModal from '../../../../../shared_components/core/modals/components/ConfirmationModal'


const CancelDeputy = ({exit, hideWarning}) => {
  const cancelAddDeputy = () => {
    Logger.log({
      name: 'canceled_add_deputy',
      payload: {}
    })

    exit()
    hideWarning()
  }

  return (
    <ConfirmationModal
      closerComponent={() => <Closer closer={hideWarning} />}
      confirmationText='Are you sure you want to close this window? You may have unsaved changes.'
      onConfirm={cancelAddDeputy}
      confirmButtonText='Yes, close'
      cancelButtonText='No, go back'
      showModal={true}
      toggleModal={hideWarning}
    />
  )
}


CancelDeputy.propTypes = {
  hideWarning: PropTypes.func,
  exit: PropTypes.func
}

export default CancelDeputy
