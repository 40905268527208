import {Fragment, useState, useCallback} from 'react'
import PropTypes from 'prop-types'

import {
  Accept,
  Cancel
} from '../../../../../dashboard/src/components/blocks/Buttons'
import ConfirmationModal from '../../../../core/modals/components/ConfirmationModal'
import Closer from '../../../../core/closer/Closer'

import './digitalSubscriptionButtonGroup.scss'

const DigitalSubscriptionButtonGroup = props => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const showModal = useCallback(() => setShowConfirmationModal(true), [])
  const hideModal = useCallback(() => setShowConfirmationModal(false), [])
  const closer = useCallback(() => <Closer onClick={hideModal} />, [])

  return (
    <Fragment>
      <div className='flex-container digital-subscription-button-group'>
        <Cancel onClick={showModal} disabled={props.processing} />
        <Accept onClick={props.submitForm} processing={props.processing}>
          Add to my Everplan
        </Accept>
      </div>
      <ConfirmationModal
        closerComponent={closer}
        confirmationText='Are you sure you want to exit?'
        onConfirm={props.toggleGapFillerAssessmentModal}
        confirmButtonText='Yes, close'
        cancelButtonText='No, go back'
        showModal={showConfirmationModal}
        toggleModal={hideModal}
      />
    </Fragment>
  )
}

DigitalSubscriptionButtonGroup.propTypes = {
  processing: PropTypes.bool,
  submitForm: PropTypes.func,
  toggleGapFillerAssessmentModal: PropTypes.func
}

export default DigitalSubscriptionButtonGroup
