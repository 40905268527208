import {connect} from 'react-redux'
import {createResource} from 'redux-json-api'
import {List, Map} from 'immutable'
import {useMutation} from '@apollo/client'
import {useState} from 'react'
import PropTypes from 'prop-types'

import AddDeputyStepOne from './AddDeputyStepOne'
import AddDeputyStepThree from './AddDeputyStepThree'
import AddDeputyStepTwo from './AddDeputyStepTwo'
import CancelDeputy from './CancelDeputy'
import Closer from '../../../../../shared_components/core/closer/Closer'
import DeputyAddedConfirmation from './DeputyAddedConfirmation'
import Modals from '../../../../../shared_components/core/modals/Modals'
import NewLoader from '../../../../../shared_components/NewLoader'

import {CONTACTS} from '../../../../../graphql/queries/userData'
import {CREATE_DEPUTY} from '../../../../../graphql/mutations/deputy'
import {DEPUTIES, SPECIFIC_ITEMS_DEPUTIES} from '../../../../../graphql/queries/deputy'
import {deputizableDomainEmails} from '../../../../../lib/emailTools'
import {findRawResourceByAttributeId, findRawResourceById, findResourceById, filterRawResourceByAttributeId, findFirmConfigByFirmOwnership} from '../../../../../lib/plan_data/userData'
import {formateDeputyPermissions} from '../../../../../lib/deputyPermissionTools'
import {useScoreLedgerEventLogger} from '../../../../../lib/ScoreLedgerEventLogger'

import './addDeputyController.scss'

const AddDeputyController = props => {
  if (props.showModal) {
    const {logWithNotification} = useScoreLedgerEventLogger(props.userConfig)
    const [showDeputyAddedConfirmation, setShowDeputyAddedConfirmation] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [step, setStep] = useState(1)

    const everplanId = props.userConfig.get('everplan-id')

    const [createDeputy] = useMutation(CREATE_DEPUTY, {
      update: (cache, {data}) => {
        const {deputies} = cache.readQuery({query: DEPUTIES, variables: {everplanId}})

        cache.writeQuery({
          query: DEPUTIES,
          data: {deputies: [...deputies, data.createDeputy]},
          variables: {everplanId}
        })
      },
      refetchQueries: [SPECIFIC_ITEMS_DEPUTIES, {query: CONTACTS, variables: {everplanId}}]
    })

    const contact = findResourceById({resourceList: props.newContacts, id: props.params?.contactId})

    const [deputy, setDeputy] = useState({
      'first-name': contact.get('first-name'),
      'last-name': contact.get('last-name'),
      email: deputizableDomainEmails({contact, organization: props.organization}).first(),
      inviteText: ''
    })

    // This scrolls the modal to the top when moving in between the screens
    const scrollToTop = () => { document.getElementsByClassName('base-modal-contents-wrapper')[0].scrollTop = 0 }

    const goBack = event => {
      if (event)
        event.preventDefault()

      // Reset the permissions if they are going back from step 2 to step one
      if (step === 2)
        setDeputy({...deputy, permissions: null})

      setStep(step - 1)
      scrollToTop()
    }

    const toggleWarning = () => { setShowWarning(!showWarning) }

    const modalCloser = () => <Closer closer={deputy['first-name'] ? toggleWarning : props.exit} />

    const name = () => `${deputy['first-name']} ${deputy['last-name']}` // Placeholder until Name component is ready

    const saveAndGoToNextStep = (newDeputyData = {}) => {
      setDeputy({...deputy, ...newDeputyData})
      setStep(step + 1)
      scrollToTop()
    }

    const updateDeputy = (newDeputyData = {}) => { setDeputy({...deputy, ...newDeputyData}) }

    const sharedProps = {
      ...props,
      deputy,
      goBack,
      nextStep: saveAndGoToNextStep,
      updateDeputy
    }

    const warningModal = () => {
      if (showWarning)
        return <CancelDeputy name={name()} hideWarning={toggleWarning} exit={props.exit} />
    }

    const submit = () => {
      const payload = {
        deathReporter: deputy['death-reporter'],
        deathVerificationPeriod: deputy['death-verification-period'],
        deputyRelationship: deputy['deputy-relationship'],
        deputyInvite: {
          firstName: deputy['first-name'],
          lastName: deputy['last-name'],
          email: deputy.email,
          customInviteText: deputy.inviteText
        },
        permissions: deputy.permissions ? formateDeputyPermissions(deputy.permissions) : []
      }

      createDeputy({
        variables: {input: payload},
        onCompleted: () => {
          setShowDeputyAddedConfirmation(true)
          props.context === 'dashboard' ? logWithNotification.addedDeputyFromPrompt() : logWithNotification.addedDeputy()
        }
      })
    }

    const showSteps = () => {
      if (step === 1)
        return <AddDeputyStepOne {...sharedProps} exit={props.exit} />
      // Need to go to step 3 directly if there are no responses
      else if (props.everplanHasResponses && step === 2)
        return <AddDeputyStepTwo {...sharedProps} />
      else
        return <AddDeputyStepThree {...sharedProps} submit={submit} />
    }

    if (showDeputyAddedConfirmation)
      return <DeputyAddedConfirmation firstName={deputy['first-name']} exit={props.exit} />

    return (
      <div className='forms-playground add-deputy-controller'>
        <Modals.ButtonPopUpModalLarge showModalOnMount closerComponent={modalCloser}>
          <NewLoader loading={props.loading}>
            {showSteps()}
          </NewLoader>
        </Modals.ButtonPopUpModalLarge>
        {warningModal()}
      </div>
    )
  } else {
    return null
  }
}

AddDeputyController.defaultProps = {
  // This component is both a route component and also can be triggered from a button, and therefore
  // when it is triggered from a route it needs to default to show
  showModal: true
}


AddDeputyController.propTypes = {
  createResource: PropTypes.func,
  context: PropTypes.string,
  deputies: PropTypes.instanceOf(List),
  everplanHasResponses: PropTypes.bool,
  exit: PropTypes.func,
  itemResponses: PropTypes.instanceOf(List),
  newContacts: PropTypes.instanceOf(List),
  organization: PropTypes.instanceOf(Map),
  params: PropTypes.shape({contactId: PropTypes.string}),
  showModal: PropTypes.bool,
  userConfig: PropTypes.instanceOf(Map)
}

const mapStateToProps = (state, {userConfig}) => {
  const firmOwnership = findRawResourceByAttributeId({
    rawResource: state.api['firm-ownerships'],
    attribute: 'everplan-id',
    id: userConfig.get('everplan-id')
  })

  const firm = findRawResourceById({
    rawResource: state.api.firms,
    id: userConfig.get('firm-id') || firmOwnership.get('ownable-id')
  })

  const organization = findRawResourceById({
    rawResource: state.api.organizations,
    id: firm.get('organization-id')
  })

  const firmConfig = findFirmConfigByFirmOwnership({firmOwnerships: state.api['firm-ownerships'], firmConfigs: state.api['firm-configs'], userConfig})

  return {
    // The only resource that isn't loaded by this point is the firmConfig and it is only needed if the user is a client --ZD
    loading: !!(userConfig.get('client') && firmConfig.isEmpty() || firm.get('organization-id') && !state.api.organizations),
    organization,
    firmConfig: findFirmConfigByFirmOwnership({firmOwnerships: state.api['firm-ownerships'], firmConfigs: state.api['firm-configs'], userConfig}),
    itemViews: filterRawResourceByAttributeId({
      attribute: 'everplan-id',
      id: userConfig.get('everplan-id'),
      rawResource: state.api['item-views']
    })
  }
}


export default connect(mapStateToProps, {createResource})(AddDeputyController)
