import {List} from 'immutable'
import {useEffect, useState} from 'react'
import {useForm, FormProvider, Controller} from 'react-hook-form'
import PropTypes from 'prop-types'

import * as Buttons from '../../blocks/Buttons'
import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import ManageItemPermissions from './ManageItemPermissions'
import PermissionSelect from '../../permissions/specific_item/shared_components/PermissionSelect'

import warningIcon from '../../../../assets/warning-icon-black.svg'
import {existingPermissionOptions} from '../../../../../lib/deputyPermissionTools'

import './managePermissionsForm.scss'


const options = ([{value: ''}]).concat(existingPermissionOptions)

const permissionByOption = ({permissions, option}) => Object.values(permissions).every(permission => permission.value === option.value)

const selectAllOption = permissions => {
  if (permissionByOption({permissions, option: options[1]}))
    return options[1]
  else if (permissionByOption({permissions, option: options[2]}))
    return options[2]
  else if (permissionByOption({permissions, option: options[3]}))
    return options[3]
  else
    return options[0]
}

const ManagePermissionsForm = props => {
  const methods = useForm({defaultValues: {selectAll: selectAllOption(props.permissions), ...props.permissions}})
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(!props.isAddDeputy)
  const selectAllValue = methods.watch('selectAll')
  const permissionValues = methods.getValues(Object.keys(props.permissions))

  useEffect(() => {
    if (selectAllValue.value && Object.values(permissionValues).some(permission => permission.value !== selectAllValue.value)) {
      return methods.setValue('selectAll', options[0])
    } else if (!selectAllValue.value) {
      const selectAllPermission = selectAllOption(permissionValues)

      if (selectAllValue.value !== selectAllPermission.value)
        return methods.setValue('selectAll', selectAllPermission)
    }
  }, [permissionValues])

  useEffect(() => {
    if (props.isAddDeputy) return

    if (methods.formState.isDirty)
      setIsSaveButtonDisabled(false)
    else
      setIsSaveButtonDisabled(true)
  }, [methods.formState.isDirty])

  const handleSelectAllChange = updatedValue => {
    Object.entries(props.permissions).forEach(([key, value]) =>
      methods.setValue(key, {...value, ...updatedValue})
    )
  }

  return (
    <div className='manage-permissions-form corp-max-deputies'>
      {props.children}
      {
        !props.everplanHasResponses &&
          <section className='empty-item-warning-text flex-container'>
            <img src={warningIcon} alt='Warning' />
            <span>You have not added any information to your Everplan.</span>
          </section>
      }
      {
        !props.itemsWithResponsesByCategory.isEmpty() && (
          <FormProvider {...methods} >
            <div className='share-all-note'>
              Sharing all includes everything in your Everplan.
              To adjust specific permissions,
              adjust each item in the respective category below.
            </div>
            <div className='share-all-container'>
              <h3>All</h3>
              <Controller
                name='selectAll'
                control={methods.control}
                render={({field}) => (
                  <PermissionSelect
                    {...field}
                    classNamePrefix='all-permissions-select'
                    filterOption={option => !!option.value}
                    onChange={value => {
                      field.onChange(value)
                      handleSelectAllChange(value)
                    }}
                    options={options}
                  />
                )}
              />
            </div>
            {
              props.itemsWithResponsesByCategory.map(category => (
                <div key={`${category.get('id')}${category.get('name')}`}>
                  <div className='category-section' >
                    <h3>{category.get('name')}</h3>
                    {
                      category.get('items').map(itemResponse => <ManageItemPermissions {...props} key={itemResponse.get('id')} itemResponse={itemResponse} />)
                    }
                  </div>
                </div>
              ))
            }
          </FormProvider>
        )
      }
      <ButtonGroup>
        <Buttons.Cancel onClick={props.cancel}>{props.cancelButtonText}</Buttons.Cancel>
        <Buttons.Button disabled={isSaveButtonDisabled} processing={props.processing} onClick={methods.handleSubmit(props.submit)}>{props.buttonText}</Buttons.Button>
      </ButtonGroup>
    </div>
  )
}

ManagePermissionsForm.propTypes = {
  buttonText: PropTypes.string,
  cancel: PropTypes.func,
  cancelButtonText: PropTypes.string,
  everplanHasResponses: PropTypes.bool,
  isAddDeputy: PropTypes.bool,
  permissions: PropTypes.object,
  itemsWithResponsesByCategory: PropTypes.instanceOf(List),
  processing: PropTypes.bool,
  submit: PropTypes.func
}

ManagePermissionsForm.defaultProps = {
  buttonText: 'Continue',
  cancelButtonText: 'Back',
  isAddDeputy: false,
  processing: false
}

export default ManagePermissionsForm
