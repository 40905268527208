import classnames from 'classnames'
import PropTypes from 'prop-types'

import {useState} from 'react'

import {Avatar, Tooltip} from '@mui/material'

import {userInitials} from '../../../../../lib/nameTools'

const UserAvatar = ({className, isPending, onClick, user}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <Tooltip
      arrow
      onClose={() => setShowTooltip(false)}
      onOpen={() => setShowTooltip(isPending)}
      open={showTooltip}
      title='Pending'
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -15],
              },
            },
          ],
        }
      }}>
      <Avatar
        className={classnames(className, {pending: isPending})}
        onClick={onClick}>
        {userInitials(user)}
      </Avatar>
    </Tooltip>  
  )
}

UserAvatar.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onClick: PropTypes.func,
  user: PropTypes.instanceOf(Map)
}

export default UserAvatar
