import classnames from 'classnames'
import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {Button} from '../blocks/Buttons'
import DeputyBox from './DeputyBox'
import DeathReporterReminderPrompt from './DeathReporterReminderPrompt'

import './myDeputies.scss'


const MyDeputies = props => (
  <div className={classnames('my-deputies', {'one-deputy': props.deputies.size === 1})}>
    <DeathReporterReminderPrompt
      hasDeputyWithDeferredItems={props.hasDeputyWithDeferredItems}
      deputies={props.deputies}
    />
    <div className='deputy-header flex-container'>
      <h1 className='flex-child'>My Deputies</h1>
      <Button className='add-deputy-button' onClick={props.goToAddDeputy}>Add</Button>
    </div>
    <section className='deputy-boxes flex-container'>
      {
        props.deputies.map(deputy => <DeputyBox {...props} key={deputy.get('id')} deputy={deputy} />)
      }
    </section>
  </div>
)


MyDeputies.propTypes = {
  currentUser: PropTypes.instanceOf(Map),
  deputies: PropTypes.instanceOf(List),
  hasDeputyWithDeferredItems: PropTypes.bool,
  updateResource: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map),
  goToAddDeputy: PropTypes.func
}

export default MyDeputies
