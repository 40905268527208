import PropTypes from 'prop-types'
import {Component} from 'react'

import Closer from '../../../../../../shared_components/core/closer/Closer'

import ConfirmationModal from '../../../../../../shared_components/core/modals/components/ConfirmationModal'


export default class ManageItemPermissionsModalCloser extends Component {
  constructor() {
    super()
    this.state = {showModal: false}

    this.closer = this.closer.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  closer() { return <Closer closer={this.toggleModal} /> }

  toggleModal() { this.setState({showModal: !this.state.showModal}) }

  render() {
    return (
      <div>
        <Closer closer={this.toggleModal} />
        <ConfirmationModal
          closerComponent={this.closer}
          confirmationText='Are you sure you want to close this window? You may have unsaved changes.'
          onConfirm={this.props.exit}
          confirmButtonText='Yes, close'
          cancelButtonText='No, go back'
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
        />
      </div>
    )
  }
}


ManageItemPermissionsModalCloser.propTypes = {
  exit: PropTypes.func.isRequired
}
