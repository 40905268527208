import {Component} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import Element from '../../../dashboard/src/components/corp_max/item/Element'
import {Accept, Cancel} from '../../../dashboard/src/components/blocks/Buttons'
import ButtonGroup from '../../core/buttons/ButtonGroup'

import './wizardField.scss'


export default class WizardField extends Component {
  componentDidMount() { this.logEvent() }

  // This is needed to make sure the event is logged when moving back and forth between screens
  componentDidUpdate(prevProps) {
    if (this.props.element.get('id') !== prevProps.element.get('id'))
      this.logEvent()
  }

  logEvent() {
    this.props.logEvent({
      name: 'viewed_item_question_on_wizard',
      payload: {
        element_id: this.props.element.get('id')
      }
    })
  }

  render() {
    return (
      <div className='wizard-field'>
        <Element
          {...this.props}
          formData={Map()}
          key={this.props.element.get('id')}
        />
        <ButtonGroup>
          {
            this.props.showBackButton && (
              <Cancel
                onClick={this.props.back}
                disabled={this.props.processing || this.props.uploadingFiles}>
                Back
              </Cancel>
            )
          }
          <Accept
            onClick={this.props.next}
            processing={this.props.processing}
            disabled={this.props.uploadingFiles}>
            Next
          </Accept>
        </ButtonGroup>
      </div>
    )
  }
}

WizardField.propTypes = {
  back: PropTypes.func,
  element: PropTypes.instanceOf(Map),
  logEvent: PropTypes.func,
  next: PropTypes.func,
  processing: PropTypes.bool,
  showBackButton: PropTypes.bool,
  uploadingFiles: PropTypes.bool
}
