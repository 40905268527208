import PropTypes from 'prop-types'

import {Button} from '@mui/material'

import nextCaret from '../dashboard/assets/right-caret-262626.svg'
import previousCaret from '../dashboard/assets/right-caret-262626.svg'

import './carouselControls.scss'

const CarouselControls = props => {
  const {
    currentCarouselIndex,
    next,
    previous,
    numberOfSlides,
    title
  } = props

  return (
    <div className='carousel-controls'>
      <h6 className='heading'>{title}</h6>
      <div className={`buttons-container slide-count-${numberOfSlides}`}>
        <Button
          className='previous'
          disabled={currentCarouselIndex === 0}
          onClick={previous}>
          <img alt='previous-button' src={previousCaret} />
        </Button>
        <Button
          className='next'
          disabled={currentCarouselIndex + 2 === numberOfSlides}
          onClick={next}>
          <img alt='next-button' src={nextCaret} />
        </Button>
      </div>
    </div>
  )
}

CarouselControls.propTypes = {
  currentCarouselIndex: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
  numberOfSlides: PropTypes.number.isRequired,
  title: PropTypes.string
}

export default CarouselControls
