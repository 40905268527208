import {useEffect} from 'react'
import {Map} from 'immutable'
import PropTypes from 'prop-types'

import AnimatedScore from '../../../../shared_components/AnimatedScore'

import {useScoreData} from '../../../../lib/hooks'

import './scoreController.scss'

const ScoreController = props => {
  const {score, startPolling, stopPolling} = useScoreData(props.userConfig.get('id'))

  useEffect(() => {
    score.get('total') === 500 ? stopPolling() : startPolling(1000)

    return () => {
      stopPolling()
    }
  }, [score, startPolling, stopPolling])

  return (
    <div className='score-controller'>
      <div className='title'>Your Everplans Score</div>
      <AnimatedScore value={score.get('total')} />
    </div>
  )
}

ScoreController.propTypes = {
  readEndpoint: PropTypes.func,
  userConfig: PropTypes.instanceOf(Map)
}

export default ScoreController
