import PropTypes from 'prop-types'
import {useState} from 'react'
import {withRouter} from 'react-router'

import Closer from '../../../../../shared_components/core/closer/Closer'

import ConfirmationModal from '../../../../../shared_components/core/modals/components/ConfirmationModal'
import Logger from '../../../../../lib/NewLogger'
import {routeContainsVerb} from '../../../lib/corpMaxRouteMatcher'

const ItemCloser = props => {
  const [showModal, setShowModal] = useState(false)

  const logEvent = name => {
    Logger.log({
      name,
      payload: {
        context: props.context,
        type: props.eventType,
        item: props.itemName,
        wildcard: props.itemName,
        compound_item: props.compoundItem
      }
    })
  }

  const logExitEvent = () => {
    if (routeContainsVerb(props.location, ['edit']))
      logEvent('discarded_edit_response')
    else
      logEvent('closed_add_new')
  }

  const exitItemForm = () => {
    logExitEvent()

    props.confirmModalExit ? setShowModal(true) : props.exit()
  }

  const returnToItemForm = () => { setShowModal(false) }

  const closer = () => <Closer closer={returnToItemForm} />

  return (
    <div>
      <Closer closer={exitItemForm} />
      <ConfirmationModal
        closerComponent={closer}
        confirmationText='Are you sure you want to close this window? You may have unsaved changes.'
        onConfirm={props.exit}
        confirmButtonText='Yes, close'
        cancelButtonText='No, go back'
        showModal={showModal}
        toggleModal={returnToItemForm}
      />
    </div>
  )
}


ItemCloser.defaultProps = {
  confirmModalExit: false
}

ItemCloser.propTypes = {
  compoundItem: PropTypes.bool,
  context: PropTypes.string,
  confirmModalExit: PropTypes.bool,
  exit: PropTypes.func.isRequired,
  itemName: PropTypes.string,
  location: PropTypes.object,
  eventType: PropTypes.string
}

export default withRouter(ItemCloser)
