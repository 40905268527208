import {gql} from '@apollo/client'

import {CONTACT_FRAGMENT} from '../fragments/userData'

export const CREATE_CONTACT = gql`
  ${CONTACT_FRAGMENT}
  mutation createContact($input: ContactInput!, $everplanId: Int!) {
    createContact(input: $input, everplanId: $everplanId) {
      ...ContactFragment
    }
  }
`
export const UPDATE_CONTACT = gql`
  ${CONTACT_FRAGMENT}
  mutation updateContact($input: ContactInput!, $everplanId: Int!) {
    updateContact(input: $input, everplanId: $everplanId) {
      ...ContactFragment
    }
  }
`

export const DELETE_CONTACT = gql`
  mutation deleteContact($id: String!) {
    deleteContact(id: $id) {
      message
    }
  }
`

export const CREATE_ASSESSMENT = gql`
  mutation createScoreAssessment($input: CreateScoreAssessmentInput!) {
    createScoreAssessment(input: $input) {
      body
      heading
      total
    }
  }
`

export const CREATE_SCORE_LEDGER_EVENT = gql`
  mutation createScoreLedgerEvent($input: CreateScoreLedgerEventInput!) {
    createScoreLedgerEvent(input: $input) {
      id
      feedbackMessage
    }
  }
`
