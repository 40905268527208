import PropTypes from 'prop-types'
import {List, Map} from 'immutable'

import {Grid} from '@mui/material'

import ItemWithoutResponsesBox from './ItemWithoutResponsesBox'

import './itemsWithoutResponses.scss'

const ItemsWithoutResponses = props => {
  const itemResponses = props.categoryItemResponses.filter(item => item.get('response-groups').isEmpty())

  if (itemResponses.isEmpty()) {
    return null
  } else {
    return (
      <div className='items-without-responses flex-child'>
        <h6 className='heading'>Add to your Everplan</h6>
        <div className='sub-heading'>
          You haven’t added anything to these sections yet.
        </div>
        <Grid
          container
          alignItems='stretch'
          columnSpacing={3}
          rowSpacing={2}
          className='items-without-responses-boxes'>
          {
            itemResponses
              .sortBy(itemResponse => itemResponse.get('sort-order'))
              .map(
                itemResponse => (
                  <Grid
                    item
                    className='item'
                    key={itemResponse.get('id')}
                    tabletMd={6}>
                    <ItemWithoutResponsesBox
                      {...props}
                      itemResponse={itemResponse}
                      key={itemResponse.get('id')}
                    />
                  </Grid>
                )
              )
          }
        </Grid>
      </div>
    )
  }
}

ItemsWithoutResponses.propTypes = {
  categoryItemResponses: PropTypes.instanceOf(List),
  userConfig: PropTypes.instanceOf(Map)
}

export default ItemsWithoutResponses
