import {Fragment, useEffect, useMemo} from 'react'

import {List, Map} from 'immutable'
import PropTypes from 'prop-types'

import {newItemPath, viewItemPath} from '../../../../../lib/urlTools'
import {categorySlug} from '../../../../../lib/plan_data/category'
import {findResourceById} from '../../../../../lib/plan_data/userData'
import Logger from '../../../../../lib/NewLogger'
import routerUtils from '../../../../../lib/routerUtils'
import {accountEntrySelectPath, preCategoryPagePath} from '../../../../../lib/urlTools'
import {eligibleForAccountsImport} from '../../../../../lib/accountPopulationTools'

import './nextBestActionNotification.scss'

const NextBestActionNotification = props => {
  const itemCategory = useMemo(() => findResourceById({resourceList: props.categories, id: props.itemResponse.get('category-id')}), [])

  const logEvent = name => {
    const itemName = props.itemResponse.get('name')

    Logger.log({
      name,
      payload: {
        context: props.context,
        everplan_id: props.itemResponse.get('everplan-id'),
        type: 'full-item',
        item: itemName,
        wildcard: itemName,
        actor: props.actor
      }
    })
  }

  useEffect(() => {
    logEvent('viewed_toast_notification')
  }, [])

  const goToPath = path => { routerUtils.push(path) }

  const goToAddAnotherPath = (itemSlug, slug, elementId, path) => {
    logEvent('clicked_add_another')

    if (eligibleForAccountsImport({item: props.itemResponse, userConfig: props.userConfig}))
      goToPath(accountEntrySelectPath({itemSlug, path: preCategoryPagePath(path)}))
    else
      goToPath(newItemPath({itemSlug, slug, elementId, path}))
  }

  const clickLink = event => {
    const elementId = props.currentElement.get('parent-element-id')
    const groupId = props.groupId
    const itemSlug = props.itemResponse.get('slug')
    const slug = categorySlug(itemCategory)
    const path = props.location.pathname

    switch (event.target.textContent.trim()) {
      case "Review what's been added":
        logEvent('viewed_response_group')
        goToPath(viewItemPath({itemSlug, slug, groupId, elementId, path}))
        break
      case 'add another':
        goToAddAnotherPath(itemSlug, slug, elementId, path)
        break
      default:
        break
    }

    props.dismissNotification()
  }

  return (
    <div className='next-best-action-notification'>
      <span>
        {`Your information has been saved in ${itemCategory.get('name')}`}
        {` under ${props.itemResponse.get('header')}.`}
      </span>
      {
        !props.hasMultipleFieldGroups && (
          <Fragment>
            <a
              className='notification-link'
              onClick={clickLink}>
                Review what's been added
            </a>
            {' '}or{' '}
            <a
              className='notification-link'
              onClick={clickLink}>
                add another
            </a>
          </Fragment>
        )
      }
    </div>
  )
}

NextBestActionNotification.propTypes = {
  actor: PropTypes.string,
  categories: PropTypes.instanceOf(List),
  context: PropTypes.string,
  currentElement: PropTypes.instanceOf(Map),
  dismissNotification: PropTypes.func,
  groupId: PropTypes.string,
  hasMultipleFieldGroups: PropTypes.bool,
  itemResponse: PropTypes.instanceOf(Map),
  location: PropTypes.object,
  userConfig: PropTypes.instanceOf(Map).isRequired
}

export default NextBestActionNotification
