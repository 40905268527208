import PropTypes from 'prop-types'
import {useRef, useState} from 'react'
import {fromJS} from 'immutable'

import ButtonGroup from '../../../../../shared_components/core/buttons/ButtonGroup'
import * as Buttons from '../../blocks/Buttons'
import Form from '../../../../../shared_components/forms/v2/Form'
import {ModalHeader} from '../../../../../shared_components/core/modals/ModalHeaders'
import Fields from '../../../../../shared_components/forms/v2/fields/index'

import './addDeputyStepThree.scss'


const AddDeputyStepThree = props => {
  const [processing, setProcessing] = useState(false)
  const formRef = useRef()

  const handleChange = () => {
    props.updateDeputy({inviteText: formRef.current.formData().getIn(['add-deputy-message-id', 'value'])})
  }

  const submitDeputyDetails = event => {
    if (event)
      event.preventDefault()

    setProcessing(true)
    props.submit()
  }

  return (
    <div className='add-deputy-step-three'>
      <div className='add-deputy-message'>
        <ModalHeader
          heading={`One last step before we send ${props.deputy['first-name']} an invitation.`}
          banner='Personal message'
        />
        <Form ref={formRef} className='core add-deputy-form'>
          <Fields.TextArea
            data={
              fromJS({
                id: 'add-deputy-message-id',
                legend: 'Add a personal message (recommended)',
                name: 'add-deputy-message-name',
                value: props.deputy.inviteText
              })
            }
            onChange={handleChange}
          />
        </Form>
      </div>
      <ButtonGroup>
        <Buttons.Cancel onClick={props.goBack}>Back</Buttons.Cancel>
        <Buttons.Button processing={processing} onClick={submitDeputyDetails}>Send invitation</Buttons.Button>
      </ButtonGroup>
    </div>
  )
}

AddDeputyStepThree.propTypes = {
  deputy: PropTypes.object,
  goBack: PropTypes.func,
  submit: PropTypes.func,
  updateDeputy: PropTypes.func
}

export default AddDeputyStepThree
