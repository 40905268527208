import PropTypes from 'prop-types'
import {Map} from 'immutable'

import CategoryHeaderIcon from '../shared/CategoryHeaderIcon'
import FeatureFlags from '../../../../../lib/FeatureFlags'
import PlaidLinkApp from '../plaid/PlaidLinkApp'
import {corpMaxWrapperUrl} from '../../../../../lib/urlTools'

import './dashboardCategoryHeader.scss'

const FINANCIAL_CATEGORY_ID = '6edb9eb4-5f1d-45e0-afc0-ae638f95d197'
const DIGITAL_CATEGORY_ID = '1a52631c-38ea-4f7a-8505-19e13b65e425'

const DashboardCategoryHeader = ({category, userConfig}) => (
  <header className='dashboard-category-header'>
    <div className='flex-container header-text-with-logo '>
      <CategoryHeaderIcon
        className='category-icon'
        categoryName={category.get('name')}
      />
      <h1>{category.get('name')}</h1>
    </div>
    {
      category.get('id') === FINANCIAL_CATEGORY_ID &&
      !userConfig.get('freemium') &&
      <PlaidLinkApp showAdditionalText={true} userConfig={userConfig} />
    }
    {
      category.get('id') === DIGITAL_CATEGORY_ID &&
      FeatureFlags.isEnabled('digital_subscription') &&
      !userConfig.get('freemium') &&
      (
        <a
          className='digital-subscription-link'
          href={corpMaxWrapperUrl('category/digital/digital-subscriptions')}>
          Import subscription services from your bank account or a credit card
        </a>
      )
    }
  </header>
)

DashboardCategoryHeader.propTypes = {
  category: PropTypes.instanceOf(Map),
  userConfig: PropTypes.instanceOf(Map)
}

export default DashboardCategoryHeader
